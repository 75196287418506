import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../components/breadcrumb'
import FragrancesList from '../components/fragrances-list'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import SEO from '../components/seo'


export const query = graphql`
  query FamilyTemplateQuery(
    $familyId: String!,
    $genderId: PostGraphile_Gender!,
    $imageMain: String!,
  ) {
    ...SiteInfo
    imageMain: file(relativePath: { eq: $imageMain }) { ...ImageMain }
    results: postgres {
      family: familyById(id: $familyId) {
        ...Family
        accords: accordsByFamilyList(orderBy: SORT_ASC) {
          ...Accord
        }
        fragrances: fragrancesList(
          filter: { gender: { equalTo: $genderId }}
        ) {
          ...Fragrance
        }
      }
    }
  }
`

export default ({ data }) => {
  const { links, meta } = data.site.siteMetadata
  const { entity } = meta
  const { family } = data.results
  const imageMain = data.imageMain.childImageSharp.fixed

  const page = `${family.name} Family`
  const title = `${family.name} ${entity.singular} Family`
  const seoTitle = `${title} - Note Families`
  const keywords = seoTitle.split(/\W+/)
  const trail = [
    {
      name: 'Notes',
      slug: links.notes,
    },
    {
      name: page,
      slug: family.slug,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description: family.about,
    image: imageMain.src,
    keywords,
    name: seoTitle,
    thing: family,
    things: family.accords,
    slug: family.slug,
  }

  return (
    <Layout>
      <SEO
        description={family.about}
        keywords={keywords}
        schema={schema}
        title={seoTitle}
      />
      <Breadcrumb trail={trail} />

      <Heading>{title}</Heading>

      <Image
        alt={family.name}
        credit={family.image}
        fixed={imageMain}
      />

      {family.about && (
        <Paragraph>
          {family.about}
        </Paragraph>
      )}

      <Heading level={2}>{family.name} Accords</Heading>
      <List>
        {family.accords.map(accord => (
          <ListItem key={accord.id}>
            <Link href={accord.slug}>
              {accord.name} Accord
            </Link>
          </ListItem>
        ))}
      </List>

      <Heading level={2}>{family.name} {entity.plural}</Heading>
      <FragrancesList fragrances={family.fragrances} />

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
